import {Type} from '@sinclair/typebox';
import {OptionalString, orientationAttr, StringEnum} from '../helpers';

const align = ['start', 'center', 'end'] as const;
export type Align = typeof align[number];

export const layoutSchema = Type.Object({
  orientation: orientationAttr,
  align: Type.Optional(StringEnum(align, {title: 'Align'})),
  gap: OptionalString('Gap Sizing'),
});

export const spacingSchema = Type.Object({
  margin: OptionalString({
    title: 'Margin',
    description:
      'Set up the top, bottom, left and right margins around your stack.',
  }),
  padding: OptionalString({
    title: 'Padding',
    description:
      'Set up the top, bottom, left and right padding inside your stack.',
  }),
});

export const backgroundSchema = Type.Object(
  {
    backgroundAttachment: OptionalString('Background Attachment'),
    backgroundColor: OptionalString('Background Color'),
    backgroundImage: OptionalString('Background Image'),
    backgroundRepeat: OptionalString('Background Repeat'),
    backgroundPosition: OptionalString('Background Position'),
    backgroundSize: OptionalString('Background Size'),
  },
  {
    title: 'Background',
  }
);

export const sizingSchema = Type.Object(
  {
    width: OptionalString({title: 'Width'}),
    height: OptionalString({title: 'Height'}),
    minWidth: OptionalString({title: 'Min Width'}),
    maxWidth: OptionalString({title: 'Max Width'}),
    minHeight: OptionalString({title: 'Min Height'}),
    maxHeight: OptionalString({title: 'Max Height'}),
  },
  {
    title: 'Sizing',
  }
);

export const borderSchema = Type.Object(
  {
    borderColor: OptionalString('Border Color'),
    borderRadius: OptionalString('Border Radius'),
    borderWidth: OptionalString('Border Width'),
    borderStyle: OptionalString('Border Style'),
  },
  {title: 'Border'}
);

export const typographySchema = Type.Object(
  {
    textColor: OptionalString('Text Color'),
    fontSize: OptionalString('Font Size'),
    fontFamily: OptionalString('Font Family'),
  },
  {
    title: 'Typography',
  }
);

export const positionSchema = Type.Object({}, {title: 'Position'});

export const opacitySchema = Type.Object(
  {
    opacity: OptionalString('Opacity'),
  },
  {
    title: 'Opacity',
  }
);
