import {Kind} from '@sinclair/typebox';
import type {Static, TUnion} from '@sinclair/typebox';
import {createSchemaValidator} from './json-schema-helpers';
import type {AsInstruction, InstructionSchema} from './typebox-helpers';
import type {Instruction} from '../hooks';
import {isJSONObject, isJSONValue, NodeFieldType} from '../types';
import type {NodeField, NodeInstanceData} from '../types';

/**
 * Checks if the given `kind` is a namespaced string where "namespaced" means
 * has two or more parts separated by a colon and each part is at least one
 * character.
 * @param kind to be tested
 * @returns `true` if `kind` is a namespaced string.
 */
export function isNamespaced(kind: string): kind is `${string}:${string}` {
  const parts = kind.split(':');
  return parts.length >= 2 && parts.every((part) => part.length >= 1);
}

/**
 * Check if the given object can be used as an `Instruction`.
 * @param o to be checked.
 * @returns `true` if `o` has the `type` and `meta` keys of appropriate types.
 */
export function isInstruction(o: unknown): o is Instruction {
  return (
    isJSONValue(o) &&
    isJSONObject(o) &&
    typeof o.type === 'string' &&
    isNamespaced(o.type) &&
    isJSONObject(o.meta)
  );
}

/**
 * Keyword in the JSON schema which indicates an instruction should be ignored by
 * the flow editor.
 */
export const FLOW_IGNORE = '$lcd-flow-ignore';

/**
 * An AJV validator configured with options and formats used by typebox.
 */
const ajv = createSchemaValidator();

/**
 * Check if the given `Instruction` matches the JSON Schema spec provided to
 * `createInstructionValidator`
 * @param instruction to test against JSON Schema
 * @returns `true` if `instruction` matches the `'publish'` or `'subscribe'`
 * cases in the JSON Schema spec narrowing type of `instruction` to access code
 * instructions.
 */
type InstructionValidateFunction<T extends InstructionSchema[]> = (
  instruction: Instruction
) => instruction is AsInstruction<T[number]>;

/**
 * Create an instruction validator function to validate against the given
 * instruction schema.
 * @param schema of the instructions.
 * @returns a function to check if a given `Instruction` matches the JSON Schema
 * spec provided as `schema`.
 */
export function createInstructionValidator<T extends InstructionSchema[]>(
  schema: TUnion<T>
): InstructionValidateFunction<T> {
  const instructionValidator = ajv.compile<Static<T[number]>>(schema);
  /**
   * Check if the given `Instruction` matches the JSON Schema spec provided to
   * `createInstructionValidator`
   * @param instruction to test against JSON Schema
   * @returns `true` if `instruction` matches the `'publish'` or `'subscribe'`
   * cases in the JSON Schema spec narrowing type of `instruction` to access code
   * instructions.
   */
  return (instruction): instruction is AsInstruction<T[number]> => {
    const publishInstruction = {
      topic: instruction.type,
      which: 'publish',
      meta: instruction.meta,
    };
    const subscribeInstruction = {
      topic: instruction.type,
      which: 'subscribe',
      meta: instruction.meta,
    };
    return (
      instructionValidator(publishInstruction) ||
      instructionValidator(subscribeInstruction)
    );
  };
}

/**
 * Generate `NodeInstanceData` for the `FlowEditor` based on the given
 * `instructionSchema`.
 * @param namespace of the instructions
 * @param instructionSchema to process
 * @returns `NodeInstanceData` for each of the `InstructionSchema` types that
 * are in `namespace`.
 */
export function createInstructionFlowNodes<T extends InstructionSchema[]>(
  namespace: string,
  instructionSchema?: TUnion<T>
): NodeInstanceData[] {
  if (typeof instructionSchema === 'undefined') {
    return [];
  }
  return instructionSchema.anyOf
    .map((obj) => {
      const isIgnored = FLOW_IGNORE in obj && obj[FLOW_IGNORE] === true;
      if (!obj.properties.topic.const.startsWith(namespace) || isIgnored) {
        return null;
      }
      const outputs: NodeInstanceData['outputs'] =
        obj.properties.which.const === 'publish'
          ? [{label: 'Instruction', type: 'event', slug: 'instruction'}]
          : undefined;
      const result: NodeInstanceData = {
        id: obj.properties.topic.const,
        label: obj.title ?? obj.properties.topic.const,
        namespace,
        description: obj.description ?? obj.properties.meta.description ?? '',
        type:
          obj.properties.which.const === 'subscribe' ? 'emitter' : 'listener',
        fields: createNodeInstanceDataFields(obj),
        inputs: createNodeInstanceDataInputs(obj),
        outputs,
      };
      return result;
    })
    .filter((o): o is NodeInstanceData => o !== null);
}

function createNodeInstanceDataFields(
  instructionSchema: InstructionSchema
): NodeInstanceData['fields'] {
  if (instructionSchema.properties.which.const !== 'publish') {
    return undefined;
  }
  const properties = instructionSchema.properties.meta.properties;
  const candidates = Object.entries(properties).map(([key, schema]) => {
    let field: NodeField;
    switch (schema[Kind]) {
      case 'String':
        field = {
          type: NodeFieldType.string,
          name: schema.title ?? key,
          slug: key,
        };
        break;
      default:
        return null;
    }
    return field;
  });
  return candidates.filter((f): f is NodeField => f !== null);
}

function createNodeInstanceDataInputs(
  instructionSchema: InstructionSchema
): NodeInstanceData['inputs'] {
  if (instructionSchema.properties.which.const !== 'subscribe') {
    return undefined;
  }
  const properties = instructionSchema.properties.meta.properties;
  return Object.entries(properties).map(([key, schema]) => {
    return {
      label: schema.title ?? key,
      type: schema[Kind] === 'String' ? 'string' : 'unknown',
      slug: key,
      value: typeof schema.default === 'string' ? schema.default : undefined,
    };
  });
}

/**
 * Determines if a `meta.about` is concerned with the module in question. Returns
 * the array of matched modules in the form of an array of `#<id>` selectors.
 * - Listener flow nodes have a selector to determine if it should fire the flow
 * on whether the selector matches the moduleNeedle of the module broadcasting.
 * - Emitter flow nodes have a selector to identify the modules it's interested
 * in instructing. A module will trigger its own internal behavior only if the selector
 * matches its moduleNeedle.
 * @param filter `about` of the instruction
 * @param needle the `#<id>` of the module in question
 * @returns
 */
export const isAboutMeCore = (
  structure: XMLDocument,
  filter: string | null | undefined,
  needle: string | null | undefined,
  /**
   * In case the needle is *, the namespace is usable as a tagName in the
   * `structure` to select only that type of module.
   */
  namespace?: string | null | undefined
): {
  selectorMatches: string[];
  moduleNeedle: string | null;
  isMatch: boolean;
} => {
  if (needle === '*' || !needle || filter === '*' || !filter) {
    // When it's an automatic match, selectorMatches should be the set of all
    // modules with a `tagName` === `namespace`.
    const selectorMatches: string[] = namespace
      ? queryStructure(structure, namespace)
      : [];

    return {
      selectorMatches,
      moduleNeedle: needle ?? null,
      isMatch: true,
    };
  }

  // Handle legacy flow usages, when we would use a raw moduleId or coreId.
  if (/^[\w-]{36}$/.test(filter.trim())) {
    filter = `[moduleId="${filter}"],[coreId="${filter}"]`;
  }

  const ids = queryStructure(structure, filter);

  return {
    selectorMatches: ids,
    moduleNeedle: needle,
    isMatch: ids?.includes(needle) ?? false,
  };
};

/**
 * Determines if a `meta.about` is concerned with the module in question. This is a
 * simplified wrapper for tests that only care about the boolean result.
 * @param filter `about` of the instruction
 * @param needle of the module in question
 * @returns
 */
export const isAboutMe = (
  structure: XMLDocument,
  filter: string | null | undefined,
  needle: string | null | undefined
): boolean => {
  if (
    needle === '*' ||
    !needle ||
    filter === '*' ||
    !filter ||
    filter.split(',').includes(needle)
  ) {
    return true;
  }
  return isAboutMeCore(structure, filter, needle).isMatch;
};

export function queryStructure(
  structure: XMLDocument | undefined,
  selector: string
): string[] {
  if (typeof structure === 'undefined') {
    return [];
  }
  return Array.from(structure.querySelectorAll(selector)).map(
    (node) => `#${node.id}`
  );
}

export function queryStructureForParents(
  structure: XMLDocument | undefined,
  selector: string
): string[] {
  if (typeof structure === 'undefined') {
    return [];
  }
  return Array.from(structure.querySelectorAll(selector)).map(
    (node) => `#${node.parentElement?.id ?? 'null'}`
  );
}
